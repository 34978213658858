import _ from 'lodash'
import { definitions } from "../supabase-autogenerated"
import supabase, { parseSupabaseDate } from "@/store/supabase-client"
import { getCorrectedAlertName, getUniqueClusters } from "@/store/findings/helpers"
import { Kind } from "@/store/resource/types"
import { filterDeadClusters } from "@/store/dead-clusters";
import { modifyTimeRangeIfOverFreePlan } from "@/plan-and-billing/plan-and-billing.utils"
import { trackError, ErrorName } from '@/utils/track-error';

type GroupedFiringAlertsOptions = {
  startDate: Date,
  endDate: Date,
  cluster: string,
  serviceKey: string
  kinds: Kind[]
}

// see https://stackoverflow.com/a/55032655/495995
type Modify<T, R> = Omit<T, keyof R> & R

// TODO: do the same for end_date
export type Finding = Modify<definitions["Findings"], {
  start_date: Date
  end_date?: Date
  rawStartDate?: Date // starts_at field. Didn't replace start_date to not break other features.
  latestRetransmission?: Date
  nameCorrected: string

  labels?: Record<string, string>
  annotations?: Record<string, string>
}>;

export type OldFinding = definitions["Issues"];

export enum FindingType {
  CHANGE = "Updates",
  ISSUE = "Issues",
}

export enum FindingPriority {
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
  INFO = "INFO",
  DEBUG = "DEBUG",
}

export type OldFindingField = keyof OldFinding

export type FindingsFilters = {
  accountId: string
  startDate: Date
  endDate: Date
  clusters?: string[]
  namespaces?: string[]
  appNames?: string[]
  names?: string[]
  types?: string[]
  fingerprints?: string[]
  priorities?: string[]
  kind?: Kind
  getJobFailure?: boolean
  limit?: number
  excludedSources?: string[]
  excludedNames?: string[]
}

// converts a FindingType to the matching string in the Finding.finding_type field
export function findingTypeToSupabase(findingType: FindingType): string {
  return findingType === FindingType.ISSUE ? "issue" : "configuration_change"
}
export function supabaseToFindingType(findingType: string): FindingType {
  if (findingType == "issue") {
    return FindingType.ISSUE
  } else if (findingType == "configuration_change") {
    return FindingType.CHANGE
  }
  throw Error(`unknown finding type ${findingType}`)
}

export const oldFindingToFinding = (oldFinding: OldFinding, firing = false) => ({
  id: oldFinding.id,
  account_id: oldFinding.account_id,
  fingerprint: oldFinding.fingerprint,
  service_key: oldFinding.service_key,
  name: oldFinding.aggregation_key,
  nameCorrected: getCorrectedAlertName(oldFinding.aggregation_key),
  category: oldFinding.finding_type,
  source: oldFinding.source || "",
  title: oldFinding.title,
  description: oldFinding.description,
  start_date: firing ? new Date(oldFinding.updated_at!) : parseSupabaseDate(oldFinding.creation_date),
  rawStartDate: new Date(oldFinding.starts_at!),
  end_date: oldFinding.ends_at === null ? undefined : new Date(oldFinding.ends_at!),
  priority: getFindingPriority(oldFinding.aggregation_key, FindingPriority[oldFinding.priority]),
  is_failure: oldFinding.failure,
  subject_type: oldFinding.subject_type,
  subject_namespace: oldFinding.subject_namespace,
  subject_name: oldFinding.subject_name,
  subject_cluster: oldFinding.cluster,
  db_creation_date: "",
  db_last_modified_date: "",
  service_name: getDisplayedServiceName(oldFinding.service_key),
  finding_name: getDisplayedFindingName(oldFinding.aggregation_key),
  labels: oldFinding.labels,
  annotations: oldFinding.annotations
} as Finding)

// TODO: see if we can replace with generic grouping
export class FindingGroup {
  grouping_key: string
  issues: Finding[]
  featuredIssue: Finding // one of the issues which we can display to represent the whole group
  protected _clusters: string[]

  constructor(grouping_key: string, issues: Finding[]) {
    this.grouping_key = grouping_key
    this.issues = issues
    this.featuredIssue = this.issues[0]
    this._clusters = getUniqueClusters(issues)
  }

  get clusters(): string[] {
    return this._clusters
  }

  containedInGroup(finding: Finding): boolean {
    return FindingGroup.getGroupingKey(finding) === this.grouping_key
  }

  static getGroupingKey(issue: Finding): string {
    return `${issue.source}/${issue.name}`
  }

  // TODO: rename to getFindingGroups
  static getIssueGroups(issues: readonly Finding[]): FindingGroup[] {
    const groups = _.groupBy(issues, this.getGroupingKey);
    return _.map(groups, (issues, key) => new FindingGroup(key, issues));
  }
}

export function getDisplayedServiceName(service_key: string | undefined) {
  if (!service_key) {
    return "unknown";
  }
  return _.last(service_key.split("/"))
}

export function getDisplayedFindingName(name: string | undefined) {
  if (!name) {
    return "unknown";
  }
  return _.last(name.split("/"))
}

export function getFindingPriority(name: string | undefined, priority: FindingPriority ) {
  if (!name || !["Kubernetes Warning Event".toLowerCase(), "KubernetesWarningEvent".toLowerCase()].includes(name.toLowerCase())) {
    return priority;
  }
  return FindingPriority.DEBUG
}

export async function fetchFindingsFromNewTable(accountID: string, startDate: Date, serviceKey?: string): Promise<readonly Finding[]> {
  let request = filterDeadClusters(supabase.client
    .from<definitions["Findings"]>("Findings")
    .select("*")
    .eq("account_id", accountID)
    .or(`start_date.gt.${startDate.toISOString()},end_date.is.null`), 'subject_cluster');

  if (serviceKey) {
    request = request.eq("service_key", serviceKey);
  }

  const response = await request;
  if (!response.data) {
    trackError(ErrorName.FAILED_TO_FETCH_FINDINGS_FROM_NEW_TABLE);
    throw Error(`Error getting findings`);
  }

  return Object.freeze(response.data.map(f => {
    return {
      ...f,
      "start_date": parseSupabaseDate(f.start_date),
      service_name: getDisplayedServiceName(f.service_key),
      finding_name: getDisplayedFindingName(f.name)
    }
  }))
}

export async function fetchOldFindingsByFilters(filters: FindingsFilters, fields?: OldFindingField[]) {
  const { startDate, endDate } = await modifyTimeRangeIfOverFreePlan(
    {
      startDate: filters.startDate,
      endDate: filters.endDate
    }
  )
  const request = filterDeadClusters(supabase.client
    .from<OldFinding>('Issues')
    .select(`${fields ?? '*'}`)
    .eq('account_id', filters.accountId)
    .gte('creation_date', startDate.toISOString())
    .lte('creation_date', endDate.toISOString())
    .order('creation_date', { ascending: false, nullsFirst: true }), 'cluster')

  if(filters.clusters && filters.clusters.length) {
    request.filter('cluster', 'in', `(${filters.clusters})`)
  }
  if(filters.namespaces && filters.namespaces.length) {
    request.filter('subject_namespace', 'in', `(${filters.namespaces})`)
  }
  if(filters.appNames && filters.appNames.length) {
    request.or(`or(${filters.appNames.map(appName => `service_key.ilike.%/${appName}`)})`)
  }
  if(filters.names && filters.names.length) {
    request.filter('aggregation_key', 'in', `(${filters.names})`)
  }
  if(filters?.excludedNames?.length) {
    request.not('aggregation_key', 'in', `(${filters.excludedNames})`)
  }
  if(filters.types && filters.types.length) {
    request.filter('finding_type', 'in', `(${filters.types})`)
  }
  if(filters.fingerprints?.length) {
    request.in('fingerprint', filters.fingerprints)
  }
  if(filters.priorities && filters.priorities.length) {
    request.filter('priority', 'in', `(${filters.priorities})`)
  }
  if(filters.kind) {
    request.ilike('service_key', `%/${filters.kind}/%`)
  }
  if(!filters.getJobFailure) {
    request.not('aggregation_key', 'in', '(job_failure,JobFailure)')
  }
  if(filters.limit) {
    request.limit(filters.limit)
  }
  if(filters.excludedSources?.length) {
    // null is an unknown value.
    // This means it's not included in the not in filter, so we have to add it manually.
    // If you need to exclude null source, please make the necessary changes.
    request.or(`source.not.in.(${filters.excludedSources.join(",")}), source.is.null`)
  }

  const response = await request
  if(!response.data) {
    trackError(ErrorName.FAILED_TO_FETCH_OLD_FINDINGS_BY_FILTERS);
    throw new Error(`Error getting findings`)
  }

  return response.data
}

export async function fetchFindingsByFilters(filters: FindingsFilters): Promise<Finding[]> {
  const oldFindings = await fetchOldFindingsByFilters(filters)
  return oldFindings.map(f => oldFindingToFinding(f))
}

export async function fetchFindingsFilters(filters: FindingsFilters) {
  const oldFindings = await fetchOldFindingsByFilters({
    ...filters,
    clusters: [], // overwrite this field in order not to send it to the server
    namespaces: []
  }, ['cluster', 'aggregation_key', 'subject_namespace', 'service_key'])

  const clusters = new Set<string>()
  const names = new Set<string>()
  const namespaces = new Set<string>()
	const appNames = new Set<string>()
  for(const oldFinding of oldFindings) {
    clusters.add(oldFinding.cluster)
    names.add(oldFinding.aggregation_key)
    // we do this on client side to avoid losing relevant filters
    if(oldFinding.subject_namespace && ((!filters.clusters || !filters.clusters.length) || filters.clusters.includes(oldFinding.cluster))) {
      namespaces.add(oldFinding.subject_namespace)
    }
    // we do this on client side to avoid losing relevant filters
    if(oldFinding.service_key && ((!filters.clusters || !filters.clusters.length) || filters.clusters.includes(oldFinding.cluster))) {
	    const chunks = oldFinding.service_key.split('/')
      appNames.add(chunks[chunks.length - 1])
    }
  }

  return {
    clusters: Array.from(clusters),
    names: Array.from(names),
    namespaces: Array.from(namespaces),
	  appNames: Array.from(appNames)
  }
}

export async function fetchFindingsFromOldTable(
  accountID: string,
  startDate: Date,
  endDate: Date,
  serviceName?: string,
  serviceKind?: string,
  namespace?: string,
  cluster?: string
): Promise<readonly Finding[]> {
  let request = filterDeadClusters(supabase.client
    .from<OldFinding>("Issues")
    .select("*")
    .eq("account_id", accountID)
    .gte('creation_date', startDate.toISOString())
    .lte('creation_date', endDate.toISOString())
    .order("starts_at", { ascending: false }), 'cluster')

	// filter by cluster

  // We can't query by subject_name / subject_type.
  // subject_name contains the pod name
  // subject_type isn't Deployment, but pod
  if (serviceName && serviceKind){
    serviceKind = serviceKind.toLowerCase()
    if(serviceKind == 'node') {
      request = request.eq("subject_name", serviceName)
      request = request.eq("subject_type", serviceKind)
    }
    else {
      request = request.ilike("service_key", `%${serviceKind}/${serviceName}`)
    }
  }
  else if (serviceName)
    request = request.ilike("service_key", `%${serviceName}%`)
  else
    request = request.ilike("service_key", `%${serviceKind}%`)

  if(namespace) {
    request.eq('subject_namespace', namespace)
  }

  if(cluster) {
    request.eq('cluster', cluster)
  }

  const response = await request;
  if (!response.data) {
    trackError(ErrorName.FAILED_TO_FETCH_FINDINGS_FROM_OLD_TABLE);
    throw Error(`Error getting findings`);
  }

  return Object.freeze(response.data.map((i) => {
    return {
      id: i.id,
      account_id: i.account_id,
      fingerprint: i.fingerprint,
      service_key: i.service_key,
      name: i.aggregation_key,
      nameCorrected: getCorrectedAlertName(i.aggregation_key),
      category: i.finding_type,
      source: i.source || "",
      title: i.title,
      description: i.description,
      start_date: parseSupabaseDate(i.creation_date),
      end_date: i.ends_at === null ? undefined : new Date(i.ends_at!),
      priority: getFindingPriority(i.aggregation_key, FindingPriority[i.priority]),
      is_failure: i.failure,
      subject_type: i.subject_type,
      subject_namespace: i.subject_namespace,
      subject_name: i.subject_name,
      subject_cluster: i.cluster,
      db_creation_date: "",
      db_last_modified_date: "",
      service_name: getDisplayedServiceName(i.service_key),
      finding_name: getDisplayedFindingName(i.aggregation_key)
    } as Finding
  }).filter(finding => finding.name !== 'job_failure' && finding.name !== 'JobFailure'));
}

export async function fetchLatestJobFailureEvent(
  accountId: string,
  cluster: string,
  namespace: string,
  name: string,
  afterDate: string
) {
  const response = await filterDeadClusters(supabase.client
    .from<OldFinding>('Issues')
    .select('*')
    .eq('account_id', accountId)
    .eq('cluster', cluster)
    .eq('subject_namespace', namespace)
    .eq('subject_name', name)
    .filter('aggregation_key', 'in', '(job_failure,JobFailure)')
    .gte('starts_at', afterDate)
    .order('starts_at', { ascending: false }), 'cluster')

  if(response.error) {
    trackError(ErrorName.FAILED_TO_FETCH_LATEST_JOB_FAILURE_EVENT);
  }

  return response.data?.map(f => oldFindingToFinding(f))[0]
}

export async function fetchGroupedFiringAlerts(accountId: string, options?: Partial<GroupedFiringAlertsOptions>) {
  const request = filterDeadClusters(supabase.client
    .from('GroupedIssues')
    .select('*')
    .eq('account_id', accountId), 'cluster')

  if(options?.startDate) {
    request.gte('updated_at', options.startDate.toISOString())
  }
  if(options?.endDate) {
    request.lte('updated_at', options.endDate.toISOString())
  }
  if(options?.cluster) {
    request.eq('cluster', options.cluster)
  }
  if(options?.serviceKey) {
    request.eq('service_key', options.serviceKey)
  }

  if(options?.kinds){
    if(options.kinds.length == 1)
      request.eq('subject_type', options.kinds[0])
    else
      request.in('subject_type', options.kinds)
  }

  const { error, data } = await request

  if(error) {
    trackError(ErrorName.FAILED_TO_FETCH_GROUPED_FIRING_ALERTS);
    throw new Error(`Error getting firing alerts`)
  }

  const alerts = data?.map(alert => oldFindingToFinding(alert, true)) ?? [] as Finding[]
  const alertsGroupedByFingerprint = Object.values(
    _.groupBy(alerts ?? [], 'fingerprint')
  )
  return alertsGroupedByFingerprint.map(alerts => {
    const alertsSortedByStartDate = [...alerts]
      .sort((a, b) => Number(a.start_date) - Number(b.start_date))
    const alertsSortedByUpdateDate = [...alerts]
      .sort((a, b) => Number(b.db_last_modified_date) - Number(a.db_last_modified_date))

    const { start_date } = alertsSortedByStartDate[0]
    const alert = alertsSortedByUpdateDate[0]
    if (alertsSortedByUpdateDate[0].end_date !== undefined) {
      return {
        ...alert,
        start_date,
        resolved: true
      }
    }

    return {
      ...alert,
      start_date
    }
  }).filter(alert => alert["resolved"] !== true)
}
