import { ref } from 'vue'
import supabase from "@/store/supabase-client"
import { RouterBuilder } from "@/router/router-builder"
import { NavigationGuardNext, Route } from "vue-router"

export const isSlackInvestigation = ref(false)

export default async function authGuard(to: Route, _from: Route, next: NavigationGuardNext) {
  if (!to.matched.some(r => r.meta.requiresAuth)) {
    return next()
  }

  isSlackInvestigation.value = to.query.hasOwnProperty('account')

  // This is not the best way to check if the user is authenticated,
  // and we need to fix it, as soon as we migrate to supabase v2
  const isAuthenticated = await supabase.isAuthenticated()

  if (!isAuthenticated) {
    return next(RouterBuilder.getLoginRoute(window.location.href))
  }

  await supabase.setUser(supabase.client.auth.user()!)
  next()
}
